import "./style/main.css";
import { useState } from "react";
import DataTable, { DataSource } from "./DataTable";
import Header from "./Header";

/**
 * Available data sources for testing. Pick one, or add your own.
 */
const dataSources: Readonly<DataSource[]> = Object.freeze<DataSource[]>([
  // A mini version for testing and small rendering verification.
  {
    file: "athletes-20.csv",
    name: "athletes-20",
    title: "Athletes (20)"
  },
  // The default data, as per the task AC.
  {
    file: "athletes-10k.csv",
    name: "athletes-10k",
    title: "Athletes (10k)"
  }
  // An amped-up input data source to test the performance of the
  // implementation when dealing with a data set of x10.
  // {
  //   file: "athletes-250k.csv",
  //   name: "athletes-250k",
  //   title: "Athletes (250k)"
  // }
]);

/**
 * App entry point.
 */
export default function App() {
  const [dataSource, setDataSource] = useState<DataSource>(dataSources[1]);
  const selectDataSource = (name: string) => {
    setDataSource(dataSources.find((ds) => ds.name === name) as DataSource);
  };
  return (
    <div className="app">
      <Header
        dataSource={dataSource}
        dataSources={dataSources}
        setDataSource={selectDataSource}
      />
      <DataTable dataSrc={dataSource} />
    </div>
  );
}
